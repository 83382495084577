<template>
  <div>
      <v-main>
        <v-container fluid>
            <v-card>
              <v-card-title>
                Data Transaksi Tertunda
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Cari Data"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-card-title class="mb-n5">
                  <input type="file" ref="file" accept=".xlsx, .xls, .csv" style="display: none" @change="onFileSelect">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        @click="editItem(null, 'auto')"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          class="mr-2"
                        >
                          mdi-file-upload
                        </v-icon>
                        Rekon Auto
                      </v-btn>
                    </template>
                    <span>Upload file rekening koran</span>
                  </v-tooltip>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="listPayment"
                :search="search"
                :loading=loading
              >
                <template v-slot:top>
                    <v-toolbar flat>
                        <!-- <v-spacer /> -->
                        <v-dialog
                          v-model="dialog"
                          max-width="500px"
                        >
                          <v-card>
                            <v-card-title>
                              <span class="headline">{{formTitle}}</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    md="12"
                                  >
                                    <v-text-field
                                      v-model="editedItem.amount"
                                      label="Nominal"
                                      :hint="'Rp'+$root.currencyFormat(editedItem.amount)"
                                      persistent-hint
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="red darken-1"
                                text
                                @click="close"
                              >
                                Batal
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                                :disabled=isLoadRekon
                              >
                                <span v-show="!isLoadRekon">Rekon</span>
                                <b-spinner v-show="isLoadRekon" small></b-spinner>
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.amount`]="{item}">
                  <b>{{$root.currencyFormat(item.amount)}}</b>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item, 'manual')"
                        color="green"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        small
                        class="mr-2"
                        @click="confirmDelete(item)"
                        color="red"
                      >
                        mdi-delete
                      </v-icon>
                </template>
              </v-data-table>
            </v-card>
            <v-row justify="center">
                <v-dialog
                v-model="dialogDel"
                persistent
                max-width="500"
                >
                <v-card>
                    <v-card-title class="headline">
                    KONFIRMASI PENGHAPUSAN
                    </v-card-title>
                    <v-card-text style="text-align:left">Konfirmasi hapus rekon an <code>{{confirmRekName}}</code> sebesar <code>{{confirmRekAmount}}</code> ?</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="dialogDel = false"
                    >
                        Batal
                    </v-btn>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="deleteItem()"
                    >
                        <span v-show="!isLoad">Konfirmasi</span>
                        <b-spinner v-show="isLoad" small></b-spinner>
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{msgSnack}} 
            </v-snackbar>
        </v-container>
      </v-main>
    <OverlayProgress :overlay=$root.overlay :isError=$root.isError />
  </div>
  
</template>

<script>
import OverlayProgress from '@/components/OverlayProgress.vue'

export default {
  components: {
    OverlayProgress
  },
  data() {
    return {
        loading: true,
        isLoad: false,
        isLoadRekon: false,
        snackbar: false,
        timeout: 4000,
        msgSnack: '',
        dialog: false,
        dialogDel: false,
        confirmRekName: '',
        confirmRekAmount: '',
        itemDel: null,
        editedItem: {
          paymentId: null,
          memberId: null,
          memberNo: null,
          name: null,
          invoiceNo: null,
          bankname: null,
          amount: null,
          file: null
        },
        defaultItem: {
          paymentId: null,
          memberId: null,
          memberNo: null,
          name: null,
          invoiceNo: null,
          bankname: null,
          amount: null,
          file: null
        },
        search: '',
        headers: [
          {
            text: 'No Anggota',
            align: 'start',
            sortable: true,
            value: 'memberNo',
          },
          { text: 'Nama', value: 'name' },
          { text: 'Faktur', value: 'invoiceNo' },
          { text: 'Bank', value: 'bankName' },
          { text: 'Nominal', value: 'amount' },
          { text: 'Tanggal Transaksi', value: 'billDate' },
          
        { text: '', value: 'actions', sortable: false },
        ],
        listPayment: [],
    }
  },
  computed:{
    formTitle(){
      return 'Rekonsiliasi'
    }
  },
  watch:{
    
  },
  methods:{
    editItem(item, method){
      this.editedItem = Object.assign({}, item)
      if(method === 'manual'){
        this.dialog = true
      }else{
        this.$refs.file.click()
      }
    },
    confirmDelete(item){
      this.dialogDel = true
      this.confirmRekName = item.name
      this.confirmRekAmount = this.$root.currencyFormat(item.amount)
      this.itemDel = item
    },
    deleteItem(){
      this.isLoad = true
      this.$root.api.sentAPI(
        {
            method: 'delete',
            api: this.$root.api.del.rekon,
            form:{
              memberId: this.itemDel.memberId,
              paymentId: this.itemDel.paymentId
            }
        }).then((res) => {
            this.isLoad = false
            this.snackbar = true
            this.dialogDel = false
            this.msgSnack = res.status.message
            this.getDataTransc() 
        })
        .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
        })
    },
    onFileSelect(e) {
      const file = e.target.files[0]
      this.editedItem.file = file
      this.save()
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    save() {
      // console.log(JSON.stringify(this.editedItem))
      var form, url = this.$root.api.post.reconAuto

      const FileUpload = new FormData()
      FileUpload.append("fileRekon", this.editedItem.file)
      form = FileUpload

      if (this.editedItem.file === null && this.editedItem.amount !== null) {
        this.isLoadRekon = true
        url = this.$root.api.post.reconManual
        form = {
          amount: this.editedItem.amount,
          memberId: this.editedItem.memberId,
          paymentId: this.editedItem.paymentId
        }
      }else if (this.editedItem.file === null && this.editedItem.amount === null) {
        this.snackbar = true
        this.msgSnack = 'Data input tidak boleh kosong'
        return
      }

      this.$root.api.sentAPI(
      {
          method: 'post',
          api: url,
          form: form
      }).then((res) => {
          // console.log(JSON.stringify(res))
          this.msgSnack = res.status.message
          this.snackbar = true
          this.isLoadRekon = false
          this.getDataTransc()
          this.close()
      })
      .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
      })
      if(!this.$root.isError) this.$root.overlay = false
    },
    async getDataTransc(){
      this.loading = true
      var id, status = 'Pending'
      this.listPayment = []
      await this.$root.api.sentAPI(
      {
          method: 'get',
          api: this.$root.api.get.status,
      }).then((res) => {
          id = res.data.paymentStatus.find(x => x.statusName === status).idStatus
      })
      .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
      })
      if(!this.$root.isError) this.$root.overlay = false
      
      
      await this.$root.api.sentAPI(
      {
          method: 'get',
          api: this.$root.api.get.listPayment + '?idStatus='+id
      }).then((res) => {
          for (let i = 0; i < res.data.length; i++) {
            this.listPayment.push({
              paymentId: res.data[i].paymentId,
              memberId: res.data[i].member.memberId,
              memberNo: res.data[i].member.memberNo,
              name: res.data[i].member.fullName,
              invoiceNo: res.data[i].invoiceNo,
              bankName: res.data[i].bankName,
              amount: res.data[i].amountIns,
              billDate: res.data[i].billDate,
              file: null
            })
          }
      })
      .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
      })
      if(!this.$root.isError) this.$root.overlay = false
      this.loading = false
    }
  },
  mounted() {
    this.getDataTransc()  
  }
}
</script>
<style scoped>
</style>
