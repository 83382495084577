var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_vm._v(" Data Transaksi Tertunda "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Cari Data","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-title',{staticClass:"mb-n5"},[_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"type":"file","accept":".xlsx, .xls, .csv"},on:{"change":_vm.onFileSelect}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.editItem(null, 'auto')}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-file-upload ")]),_vm._v(" Rekon Auto ")],1)]}}])},[_c('span',[_vm._v("Upload file rekening koran")])])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.listPayment,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Nominal","hint":'Rp'+_vm.$root.currencyFormat(_vm.editedItem.amount),"persistent-hint":""},model:{value:(_vm.editedItem.amount),callback:function ($$v) {_vm.$set(_vm.editedItem, "amount", $$v)},expression:"editedItem.amount"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Batal ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.isLoadRekon},on:{"click":_vm.save}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadRekon),expression:"!isLoadRekon"}]},[_vm._v("Rekon")]),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoadRekon),expression:"isLoadRekon"}],attrs:{"small":""}})],1)],1)],1)],1)],1)]},proxy:true},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(_vm.$root.currencyFormat(item.amount)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.editItem(item, 'manual')}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.confirmDelete(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.dialogDel),callback:function ($$v) {_vm.dialogDel=$$v},expression:"dialogDel"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" KONFIRMASI PENGHAPUSAN ")]),_c('v-card-text',{staticStyle:{"text-align":"left"}},[_vm._v("Konfirmasi hapus rekon an "),_c('code',[_vm._v(_vm._s(_vm.confirmRekName))]),_vm._v(" sebesar "),_c('code',[_vm._v(_vm._s(_vm.confirmRekAmount))]),_vm._v(" ?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.dialogDel = false}}},[_vm._v(" Batal ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.deleteItem()}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoad),expression:"!isLoad"}]},[_vm._v("Konfirmasi")]),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoad),expression:"isLoad"}],attrs:{"small":""}})],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.msgSnack)+" ")])],1)],1),_c('OverlayProgress',{attrs:{"overlay":_vm.$root.overlay,"isError":_vm.$root.isError}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }